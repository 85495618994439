import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class OnlineMembershipPackageController extends Controller {
  static targets = ['membershipForm', 'membershipPlan', 'startDate',
                    'selfBooking', 'membershipHistoryUrl', 'additionalMembers'];

  connect() {
    this.selfBooking = this.selfBookingTargets[0]?.value;
    this.startDate = this.startDateTargets[0]?.value;

    window.onChangeStartDate = (val) => {
      this.startDate = moment(val.date).format('YYYY-MM-DD');
      this.updateMembershipHistory();
      this.reloadData();
    }
  }

  selectMembershipType() {
    this.updateMembershipHistory();
    this.reloadData();
  }

  changeSelfBookingOption(e) {
    this.selfBooking = e.target.value;
    this.updateMembershipHistory();
    this.reloadData({ self_booking: e.target.value });
  }

  updateSameDetailsAsCustomer(e) {
    const target = e.target;
    const url = target.dataset.url;

    axios
      .put(url, { same_details_as_customer: target.checked })
      .then((response) => {
        target.closest('.online_member').innerHTML = response.data;
      })
      .catch((e) => {
        Helper.flash_message('error', e.response.data.errors);
      });
  }

  addMember(e) {
    e.preventDefault();

    axios
      .post(e.target.href)
      .then((response) => {
        this.additionalMembersTarget.insertAdjacentHTML('beforeend', response.data);
        this.reloadData();
      });
  }

  removeMember(e) {
    e.preventDefault();

    axios
      .delete(e.target.href)
      .then((response) => {
        e.target.closest('.online_member').innerHTML = '';
        this.reloadData();
      });
  }

  updateMembershipHistory() {
    axios
      .put(this.membershipHistoryUrlTarget.dataset.url, {
        online_membership_plan_id: this.membershipPlanTarget.value,
        start_date: this.startDate,
        self_booking: this.selfBooking
      })
      .catch((e) => Helper.flash_message('error', e.response.data.errors));
  }

  reloadData(params) {
    axios
      .get(this.membershipFormTarget.action, {
        params: params,
        headers: { 'Accept': 'text/vnd.turbo-stream.html' }
      })
      .then((r) => Turbo.renderStreamMessage(r.data))
      .catch((e) => Helper.flash_message('error', e.response.data.errors));
  }
}

export default OnlineMembershipPackageController;
